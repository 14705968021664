import './main.css';
import {Elm} from './Main.elm';

const inIos = Boolean(window.webkit)

if (inIos) {
    window.webkit.messageHandlers.setAccessToken.postMessage(accessToken);
}


window.onSpotifyWebPlaybackSDKReady = () => {
    console.log("Spotify Web Playback SDK ready!")


    elmApp = Elm.Main.init({
        node: document.getElementById('root'),
        flags: {accessToken: accessToken, env: inIos ? "iOs" : "web"}
    });

    if (inIos) {
        elmApp.ports.iosCommands.subscribe(function (data) {
                console.log("webkit.command: " + data.command);
                if (data.command === "ensureConnected") {
                    window.webkit.messageHandlers.ensureConnected.postMessage(data.trackUri);
                }
            }
        );
    }

    const player = new Spotify.Player({
        name: 'Simple Web',
        getOAuthToken: cb => {
            cb(accessToken);
        }
    });

    const sendSpotifyEvent = (eventType, payload) => {
        elmApp.ports.webPlayerEvents.send(
            {
                eventType: eventType,
                payload: payload
            }
        );
    }

    // Error handling
    player.addListener('initialization_error', ({message}) => {
        console.error(message);
        sendSpotifyEvent(
            "error",
            {
                errorType: "initialization",
                message: message
            }
        );

    });
    player.addListener('authentication_error', ({message}) => {
        console.error(message);
        sendSpotifyEvent(
            "error",
            {
                errorType: "authentication",
                message: message
            }
        );
    });
    player.addListener('account_error', ({message}) => {
        console.error(message);
        sendSpotifyEvent(
            "error",
            {
                errorType: "account",
                message: message
            }
        );
    });
    player.addListener('playback_error', ({message}) => {
        console.error(message);
        sendSpotifyEvent(
            "error",
            {
                errorType: "playback",
                message: message
            }
        );
    });

    // Ready
    player.addListener('ready', ({device_id}) => {
        console.log('Ready with Device ID', device_id);
        sendSpotifyEvent(
            "ready",
            {
                deviceId: device_id
            }
        );
    });

    // Not Ready
    player.addListener('not_ready', ({device_id}) => {
        console.log('Device ID has gone offline', device_id);
        sendSpotifyEvent(
            "error",
            {
                errorType: "not_ready",
                message: "Device " + device_id + " has gone offline"
            }
        );
    });

    // Connect to the player!
    player.connect().then(success => {
        if (success) {
            console.log('The Web Playback SDK successfully connected to Spotify!');
        } else {
            console.error('The Web Playback SDK could not connect to Spotify. They do not provide a reason for this :(');
        }
    });


};

